<template>
  <v-sheet class="salesSummary" style="height: calc(100vh - 340px)">
    <v-row>
      <v-layout class="mx-3">
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label me-0 d-flex align-center"
                    style="font-weight: 600; font-size: 18px"
                  >
                    <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="blue darken-4" dark>person</v-icon>
                    </VAvatar>
                    <span class="d-flex align-center flex-wrap">
                      <span class="mr-2" style="max-width: 92px; display: inline-block">
                        All In</span
                      >
                      <v-chip outlined style="font-size: 18px; font-weight: 600">{{ year }}</v-chip>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#0D47A1"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(thisYear) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    This Month</v-list-item-title
                  >
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="red" style="font-size: 18px; color: white; font-weight: 600">{{
                      formatMoney(month)
                    }}</v-chip>
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid orange">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="orange lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    Last Month</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="orange"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(lastMonth) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="3" class="">
          <v-card class="mx-auto" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label d-flex align-center"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <span style="max-width: 90px; display: inline-flex"
                      >Last 4 Month<br />
                      Average</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="green"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(averageSum) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-layout>
    </v-row>
    <v-card-text class="">
      <v-row>
        <!-- <v-col md="3" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter By Date Range</label
          >
          <DateRangePicker
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="item-sale-duration"
            placeholder="Date Range"
          ></DateRangePicker>
        </v-col> -->
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter by Year</label
          >
          <SalesCompleteInput
            hide-details
            class="mt-3"
            @change="searchSales"

            :disabled="pageLoading"
            :loading="pageLoading"
            :items="yearList"
            id="segment"
            v-model="search_year"
            placeholder="Select Year"
          ></SalesCompleteInput>
        </v-col>
       
        <!-- <v-col md="4" class="mt-5">
          <v-row>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    @click="searchSales"
                    tile
                    class="ml-2 white--text me-1"
                    >Filter</v-btn
                  >
                </template>
                <span>Filter</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    @click="reset"
                    class="ml-2 white--text me-1"
                    >Reset Filter</v-btn
                  >
                </template>
                <span>Reset Filter</span>
              </v-tooltip>
             
            </v-flex>
          </v-row>
        </v-col> -->
        <v-col class="mt-5 justify-content-end">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs"
                  :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox
                        :ripple="false"
                        hide-details
                        color="#0d47a1"
                        v-model="defaultColShow"
                        v-bind:value="item.field"
                        :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()"
                        class="m-0"
                      ></v-checkbox>
                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>
                  <v-divider
                    v-if="index < customThead.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <v-simple-table fixed-header class="bt-table table_height_sales">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in customThead"
              :key="heading.title"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">{{ heading.headerName }}</div>
            </th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="sales_summary.length">
            <tr
              v-for="(row, index) in sales_summary"
              :key="index"
              :class="index % 2 === 0 && 'blue lighten-5'"
              class="salessummary-listing-tr"
              v-on:click="routeToDetail(row)"
            >
              <td v-for="(th, index) in customThead" :key="'key_' + index" class="cursor-pointer">
                <v-chip
                  small
                  color="#4CAF50"
                  v-if="th.field === 'date' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row.full_date }}
                </v-chip>

                <div v-else-if="th.field === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        class="mx-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        link
                        v-on:click="routeToDetail(row)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="th.field === 'master' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <!-- <ValueTemplate
                    v-model="formatMoney(row.cash_sale)"
                    :title="row.cash_sale"
                  ></ValueTemplate> -->
                  <template>
                    {{ formatMoney(row.master_pay) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'bank_Fee' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.bank_fee) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'estimated' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.total_estimated_fee) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'received' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.banking) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'discrepancy' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.discrepancy) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'online_banking' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.online_banking) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'touch_and_go' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.touch) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'qr_pay' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.qr_pay) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'grab_pay' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.grab_pay) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'boost' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.boost) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'grab_food' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.grab_food) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'food_panda' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.food_panda) }}
                  </template>
                </div>

                <div
                  v-else-if="th.field === 'foc' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.foc) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'voucher_claim' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.voucher_claim) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'voucher_sold' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.voucher_sold) }}
                  </template>
                </div>
                <div
                  v-else-if="th.field === 'voucher_award' && th.status"
                  link
                  v-on:click="routeToDetail(row)"
                >
                  <template>
                    {{ formatMoney(row.voucher_award) }}
                  </template>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="18">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no Debit sales summary at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 18" :key="idr">
            <td v-for="idk in 18" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="sales_summary.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Desctiption</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                width="70"
                height="70"
                src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate
      v-model="order"
      :customer-id="salesId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>

    <DeleteTemplate
      type="sales summary"
      delete-note="All leave of this employee will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getSummary()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_MASTER_SALES, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
// import DateRangePicker from "@/view/components/DateRangePicker";
// import ValueTemplate from "@/view/components/ValueTemplate";
import PosMixin from "@/core/mixins/pos.mixin";
import { filter, map } from "lodash";

import { POST } from "@/core/services/store/request.module";
import SalesCompleteInput from "@/view/components/SalesCompleteInput";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
import moment from "moment-timezone";
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  mixins: [PosMixin],

  name: "sales-update",
  title: "Listing Sales",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      search_month: null,
      search_year: new Date().getFullYear(),
      defaultColDefs: [],
      customThead: [],
      years: [
        {
          name: "2030",
          value: "2030",
        },
        {
          name: "2029",
          value: "2029",
        },
        {
          name: "2028",
          value: "2028",
        },
        {
          name: "2027",
          value: "2027",
        },
        {
          name: "2026",
          value: "2026",
        },
        {
          name: "2025",
          value: "2025",
        },
        {
          name: "2024",
          value: "2024",
        },
        {
          name: "2023",
          value: "2023",
        },
        {
          name: "2022",
          value: "2022",
        },
      ],
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],

      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      sales_summary: [],
      currentPage: 1,
      month: 0,
      lastMonth: 0,
      averageSum: 0,
      thisYear: 0,
      pageLimit: 1,
      year: "2024",
      showingFrom: 0,
      yearList: [],
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      salesId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      salestableHeading: [
        { title: "ACTION", status: true },
        { title: "DATE", status: true },
        { title: "TOTAL SALES", status: true },
        { title: "CASH SALES", status: true },
        { title: "VISA", status: true },
        { title: "DEBIT", status: true },
        { title: "MASTERCARD", status: true },
        { title: "ONLINE BANKING", status: true },
        { title: "TOUCH AND GO", status: true },
        { title: "QR PAY", status: true },
        { title: "GRAB PAY", status: true },
        { title: "BOOST", status: true },
        { title: "GRAB FOOD", status: true },
        { title: "FOOD PANDA", status: true },
        { title: "FOC", status: true },
        { title: "VOUCHER CLAIM", status: true },
        { title: "VOUCHER SOLD", status: true },
        { title: "VOUCHER AWARD", status: true },
      ],
      salesSummaryDate: [
        {
          id: 1,
          action: { value: "", key: "ACTION" },
          date: { value: "May 2023", key: "DATE" },
          cash_sales: { value: "12.00", key: "CASH SALES" },
          visa: { value: "", key: "VISA" },
          debit: { value: "0.00", key: "DEBIT" },
          mastercard: { value: "4.00", key: "MASTERCARD" },
          union: { value: "1.00", key: "UNION" },
          may_bank: { value: "22.00", key: "MAY BANK" },
          boost: { value: "0.00", key: "BOOST" },
          grab_food: { value: "3.00", key: "GRAB FOOD" },
          grab_pay: { value: "3.00", key: "GRAB PAY" },
          shoppepay: { value: "1.00", key: "SHOPPEPAY" },
          online_bank_in: { value: "4.00", key: "ONLINE BANK IN" },
          touch_and_go: { value: "0.00", key: "TOUCH AND GO" },
          food_panda: { value: "1.00", key: "FOOD PANDA" },
          city_ledger: { value: "1.00", key: "CITY LEDGER" },
          foc: { value: "", key: "FOC" },
          total_sales: { value: "1.00", key: "TOTAL SALES" },
          voucher: { value: "1.00", key: "VOUCHER" },
        },
        {
          id: 2,
          action: { value: "", key: "ACTION" },
          date: { value: "June 2023", key: "DATE" },
          cash_sales: { value: "22.00", key: "CASH SALES" },
          visa: { value: "14.00", key: "VISA" },
          debit: { value: "10.00", key: "DEBIT" },
          mastercard: { value: "24.00", key: "MASTERCARD" },
          union: { value: "12.00", key: "UNION" },
          may_bank: { value: "", key: "MAY BANK" },
          boost: { value: "0.00", key: "BOOST" },
          grab_food: { value: "3.00", key: "GRAB PAY" },
          grab_pay: { value: "23.00", key: "GRAB FOOD" },
          shoppepay: { value: "16.00", key: "SHOPPEPAY" },
          online_bank_in: { value: "", key: "ONLINE BANK IN" },
          touch_and_go: { value: "0.00", key: "TOUCH AND GO" },
          food_panda: { value: "1.00", key: "FOOD PANDA" },
          city_ledger: { value: "1.00", key: "CITY LEDGER" },
          foc: { value: "", key: "FOC" },
          total_sales: { value: "1.00", key: "TOTAL SALES" },
          voucher: { value: "1.00", key: "VOUCHER" },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    salestableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getSummary();
    },
    listingSearch() {
      this.getSummary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSummary();
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
  methods: {
    searchSales() {
      this.getSummary();
    },
    reset() {
      this.search_month = null;
      this.search_year = null;
      this.getSummary();
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getSummary();
          });
      });
    },
    formatedate(param) {
      return moment(param).format("MM/YYYY");
    },
    // getColValue(val) {
    //   if (val.includes(" ")) {
    //     return val.split(" ").join("_").toLowerCase();
    //   } else {
    //     return val.toLowerCase();
    //   }
    // },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.defaultColDefs[this.dragStartIndex];
      this.defaultColDefs.splice(this.dragStartIndex, 1);
      this.defaultColDefs.splice(this.dragOverIndex, 0, dragStartElement);
      console.log(this.salestableHeading, "dragStartElement");

      this.$nextTick(() => {
        this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.getSummary();
          });
      });
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.salesId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.salesId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.salesId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "sales-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(row) {
      console.log(row);
      this.$router.push({
        name: "mastercard-detail",
        params: { id: row.month },
        query: { t: new Date().getTime(), year: row.year },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `sales/${id}`;
      this.deleteDialog = true;
    },
    async getSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
          search_month: this.search_month,
          search_year: this.search_year,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const {
          rows,
          totalPages,
          showingFrom,
          showingTo,
          totalRows,
          thisYear,
          year,
          lastMonth,
          month,
          averageSum,
          theads,
        } = await GET_MASTER_SALES(form);
        this.sales_summary = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.thisYear = thisYear;
        this.year = year;
        this.lastMonth = lastMonth;
        this.averageSum = averageSum;
        this.month = month;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");
        let currentYear = new Date().getFullYear();
        let startYear = 2022;
        this.yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
          this.yearList.push(year);
        }

        // console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {});
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSummary();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "MASTERCARD SALES");
    // await this.getTemplateListing();
  },

  components: {
    // DateRangePicker,
    SalesCompleteInput,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    SearchTemplate,
    SelectInput,
    Dialog,
    // ValueTemplate,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_sales .v-data-table__wrapper {
  height: calc(100vh - 360px);
}

::-webkit-scrollbar {
  height: 10px;
}

.salessummary-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.salessummary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
